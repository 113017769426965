<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 175px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th
              role="columnheader"
              class="text-center"
              style="max-width: 100px; min-width: 80px"
            >
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center"
              style="max-width: 100px; min-width: 80px"
            >
              <SelectDeliveryCompany
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="delivery_company"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'thieu_hang'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_20") }} <br />
              <span class="error--text">
                {{ formatNumber(sumAll["thieu_hang"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'cho_ma_van_chuyen'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_35_1") }} <br />
              <span class="error--text">
                {{ formatNumber(sumAll["cho_ma_van_chuyen"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'thieu_tem'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.missing_stamp") }} <br />
              <span class="error--text">
                {{ formatNumber(sumAll["thieu_tem"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'tiep_nhan'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_40") }} <br />
              <span class="warning--text">
                {{ formatNumber(sumAll["tiep_nhan"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'cho_hoan'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.wait_return") }} <br />
              <span class="warning--text">
                {{ formatNumber(sumAll["cho_hoan"]) }}
              </span>
            </th>
            <th role="columnheader" class="text-center" style="min-width: 65px">
              {{ $t("labels.order_status_220") }} <br />
              <span
                class="warning--text cursor-pointer"
                @click="
                  showDetail(
                    filters.id_warehouse,
                    filters.id_delivery_company || 9999,
                    'nhan_hoan'
                  )
                "
                style="min-width: 65px"
              >
                {{ formatNumber(sumAll["nhan_hoan"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'dang_xu_ly'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_60_1") }} <br />
              <span class="primary--text">
                {{ formatNumber(sumAll["dang_xu_ly"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'pickup'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_100") }} <br />
              <span class="primary--text">
                {{ formatNumber(sumAll["pickup"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'dong_goi'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_160") }} <br />
              <span class="primary--text">
                {{ formatNumber(sumAll["dong_goi"]) }}
              </span>
            </th>
            <!-- <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'den_han'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_due") }} <br />
              <span class="purple--text">
                {{ formatNumber(sumAll["den_han"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'qua_han'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_overdue") }} <br />
              <span class="purple--text">
                {{ formatNumber(sumAll["qua_han"]) }}
              </span>
            </th> -->
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'nhap_hoan'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_240") }} <br />
              <span class="success--text">
                {{ formatNumber(sumAll["nhap_hoan"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'ban_giao'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.order_status_200") }} <br />
              <span class="success--text">
                {{ formatNumber(sumAll["ban_giao"]) }}
              </span>
            </th>
            <th
              role="columnheader"
              class="text-center cursor-pointer"
              @click="
                showDetail(
                  filters.id_warehouse,
                  filters.id_delivery_company || 9999,
                  'boi_thuong'
                )
              "
              style="min-width: 65px"
            >
              {{ $t("labels.indemnify") }} <br />
              <span class="success--text">
                {{ formatNumber(sumAll["boi_thuong"]) }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`s_${key}`"
            class="text-center tr-h-36px"
          >
            <td>{{ item.warehouse_code }}</td>
            <td>{{ item.delivery_company_name }}</td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'thieu_hang'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.thieu_hang) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'cho_ma_van_chuyen'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.cho_ma_van_chuyen) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'thieu_tem'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.thieu_tem) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'tiep_nhan'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.tiep_nhan) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'cho_hoan'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.cho_hoan) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'nhan_hoan'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.nhan_hoan) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'dang_xu_ly'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.dang_xu_ly) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'pickup'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.pickup) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'dong_goi'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.dong_goi) }}
            </td>

            <!-- <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'den_han'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.den_han) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'qua_han'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.qua_han) }}
            </td> -->
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'nhap_hoan'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.nhap_hoan) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'ban_giao'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.ban_giao) }}
            </td>
            <td
              @click="
                showDetail(
                  item.id_warehouse,
                  item.id_delivery_company,
                  'boi_thuong'
                )
              "
              class="cursor-pointer"
            >
              {{ formatNumber(item.boi_thuong) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <!-- <v-col cols="12" md="3">
        <div class="mt-5"></div>
        <DateRangeFilter
          :label="$t('labels.date')"
          :placeholder="$t('labels.date')"
          name="date"
          sort-name="date"
        
          :sorting="filters.sort_by"
          :default-value="defaultToday()"
          @onFilter="onFilterChange"
          @onSort="onSortChange"
        />
      </v-col> -->

      <v-col cols="12" md="3">
        <div class="mt-5"></div>
        <v-btn
          small
          color="success"
          style="height: 40px"
          @click="getList"
          class="mr-2"
        >
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12" md="6"></v-col>

      <v-col cols="12" md="3">
        <v-row no-gutters class="table-footer-count">
          <v-col cols="6">
            <div v-for="(cItem, cKey) in countByActionItem" :key="`ci_${cKey}`">
              <span style="display: inline-block; width: 70px">
                {{ $t(`labels.${cItem.text}`) }}
              </span>
              <b>{{ cItem.value }}</b>
            </div>
          </v-col>
          <v-col cols="6">
            <div
              v-for="(cItem, cKey) in countByActionOrder"
              :key="`co_${cKey}`"
            >
              <span style="display: inline-block; width: 70px">
                {{ $t(`labels.${cItem.text}`) }}
              </span>
              <b>{{ cItem.value }}</b>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="detailDialog" max-width="100vw">
      <DashboardDetail
        v-if="detailDialog"
        :id-warehouse="selected.id_warehouse"
        :id-delivery-company="selected.id_delivery_company"
        :state="selected.state"
        :date="filters.date"
        :id-orders="selected.id_orders"
        @cancel="hideDetail"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import moment from "moment/moment";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectDeliveryCompany: () =>
      import("@/components/table/SelectDeliveryCompany"),
    // DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    DashboardDetail: () => import("@/components/orders/DashboardDetail"),
  },
  name: "Dashboard",
  data: () => ({
    items: [],
    outOfStockIds: [],
    filters: {
      date: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    },
    isLoading: false,
    detailDialog: false,
    checkOrderReturnDialog: false,
    selected: {},
    countByActionItem: [],
    countByActionOrder: [],
  }),
  computed: {
    sumAll() {
      const sum = {
        cho_ma_van_chuyen: 0,
        thieu_hang: 0,
        thieu_tem: 0,
        tiep_nhan: 0,
        cho_hoan: 0,
        nhan_hoan: 0,
        dang_xu_ly: 0,
        pickup: 0,
        dong_goi: 0,
        can_ban_giao: 0,
        ban_giao: 0,
        nhap_hoan: 0,
        boi_thuong: 0,
        den_han: 0,
        qua_han: 0,
      };
      if (!this.items || this.items.length === 0) {
        return sum;
      }
      this.items.forEach((item) => {
        sum.cho_ma_van_chuyen += +item.cho_ma_van_chuyen || 0;
        sum.thieu_hang += +item.thieu_hang || 0;
        sum.thieu_tem += +item.thieu_tem || 0;
        sum.tiep_nhan += +item.tiep_nhan || 0;
        sum.cho_hoan += +item.cho_hoan || 0;
        sum.nhan_hoan += +item.nhan_hoan || 0;
        sum.dang_xu_ly += +item.dang_xu_ly || 0;
        sum.pickup += +item.pickup || 0;
        sum.dong_goi += +item.dong_goi || 0;
        sum.can_ban_giao +=
          (+item.dang_xu_ly || 0) + (+item.pickup || 0) + (+item.dong_goi || 0);
        sum.ban_giao += +item.ban_giao || 0;
        sum.nhap_hoan += +item.nhap_hoan || 0;
        sum.boi_thuong += +item.boi_thuong || 0;
        sum.den_han += +item.den_han || 0;
        sum.qua_han += +item.qua_han || 0;
      });
      return sum;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    defaultToday() {
      return [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
    },
    setViewOption(mode) {
      this.viewOption = mode;
    },
    getList: debounce(function () {
      httpClient
        .post("/orders-report-new", { ...this.filters })
        .then(({ data }) => {
          this.items = [...data.rows];
        });
      httpClient.post("/work-today", { ...this.filters }).then(({ data }) => {
        this.countByActionItem = [
          {
            text: "receipt_1",
            value: data.total_receipted,
          },
          {
            text: "work_type_2",
            value: data.total_stowing,
          },
          {
            text: "return_1",
            value: data.total_return_imported,
          },
          {
            text: "stowing_return_1",
            value: data.total_return_stowing,
          },
        ];
        this.countByActionOrder = [
          {
            text: "pickup_1",
            value: data.total_picked,
          },
          {
            text: "pack_1",
            value: data.total_packing,
          },
          {
            text: "receive_return_1",
            value: data.total_return_receive,
          },
        ];
      });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDetail(id_warehouse, id_delivery_company, state, id_orders) {
      this.selected = {
        id_warehouse,
        id_delivery_company: id_delivery_company || null,
        state,
        id_orders,
      };
      this.detailDialog = true;
    },
    hideDetail() {
      this.selected = {};
      this.detailDialog = false;
    },
    showCheckOrderReturnDialog() {
      this.checkOrderReturnDialog = true;
    },
    hideCheckOrderReturnDialog() {
      this.checkOrderReturnDialog = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
