var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',{staticClass:"table-padding-2",attrs:{"fixed-header":"","height":"calc(100vh - 175px)"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"max-width":"100px","min-width":"80px"},attrs:{"role":"columnheader"}},[_c('SelectWarehouse',{attrs:{"label":_vm.$t('labels.warehouse'),"placeholder":_vm.$t('labels.warehouse'),"name":"id_warehouse","sort-name":"warehouse_code","sorting":_vm.filters.sort_by},on:{"onFilter":_vm.onFilterChange,"onSort":_vm.onSortChange}})],1),_c('th',{staticClass:"text-center",staticStyle:{"max-width":"100px","min-width":"80px"},attrs:{"role":"columnheader"}},[_c('SelectDeliveryCompany',{attrs:{"label":_vm.$t('labels.delivery_company'),"placeholder":_vm.$t('labels.delivery_company'),"name":"id_delivery_company","sort-name":"delivery_company","sorting":_vm.filters.sort_by},on:{"onFilter":_vm.onFilterChange,"onSort":_vm.onSortChange}})],1),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'thieu_hang'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_20"))+" "),_c('br'),_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["thieu_hang"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'cho_ma_van_chuyen'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_35_1"))+" "),_c('br'),_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["cho_ma_van_chuyen"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'thieu_tem'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.missing_stamp"))+" "),_c('br'),_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["thieu_tem"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'tiep_nhan'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_40"))+" "),_c('br'),_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["tiep_nhan"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'cho_hoan'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.wait_return"))+" "),_c('br'),_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["cho_hoan"]))+" ")])]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_220"))+" "),_c('br'),_c('span',{staticClass:"warning--text cursor-pointer",staticStyle:{"min-width":"65px"},on:{"click":function($event){return _vm.showDetail(
                  _vm.filters.id_warehouse,
                  _vm.filters.id_delivery_company || 9999,
                  'nhan_hoan'
                )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["nhan_hoan"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'dang_xu_ly'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_60_1"))+" "),_c('br'),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["dang_xu_ly"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'pickup'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_100"))+" "),_c('br'),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["pickup"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'dong_goi'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_160"))+" "),_c('br'),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["dong_goi"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'nhap_hoan'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_240"))+" "),_c('br'),_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["nhap_hoan"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'ban_giao'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.order_status_200"))+" "),_c('br'),_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["ban_giao"]))+" ")])]),_c('th',{staticClass:"text-center cursor-pointer",staticStyle:{"min-width":"65px"},attrs:{"role":"columnheader"},on:{"click":function($event){return _vm.showDetail(
                _vm.filters.id_warehouse,
                _vm.filters.id_delivery_company || 9999,
                'boi_thuong'
              )}}},[_vm._v(" "+_vm._s(_vm.$t("labels.indemnify"))+" "),_c('br'),_c('span',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.sumAll["boi_thuong"]))+" ")])])])]),_c('tbody',_vm._l((_vm.items),function(item,key){return _c('tr',{key:`s_${key}`,staticClass:"text-center tr-h-36px"},[_c('td',[_vm._v(_vm._s(item.warehouse_code))]),_c('td',[_vm._v(_vm._s(item.delivery_company_name))]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'thieu_hang'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.thieu_hang))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'cho_ma_van_chuyen'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.cho_ma_van_chuyen))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'thieu_tem'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.thieu_tem))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'tiep_nhan'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.tiep_nhan))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'cho_hoan'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.cho_hoan))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'nhan_hoan'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.nhan_hoan))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'dang_xu_ly'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.dang_xu_ly))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'pickup'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.pickup))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'dong_goi'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.dong_goi))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'nhap_hoan'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.nhap_hoan))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'ban_giao'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.ban_giao))+" ")]),_c('td',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetail(
                item.id_warehouse,
                item.id_delivery_company,
                'boi_thuong'
              )}}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.boi_thuong))+" ")])])}),0)]},proxy:true}])}),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"mt-5"}),_c('v-btn',{staticClass:"mr-2",staticStyle:{"height":"40px"},attrs:{"small":"","color":"success"},on:{"click":_vm.getList}},[_c('v-icon',[_vm._v("mdi-sync")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"table-footer-count",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.countByActionItem),function(cItem,cKey){return _c('div',{key:`ci_${cKey}`},[_c('span',{staticStyle:{"display":"inline-block","width":"70px"}},[_vm._v(" "+_vm._s(_vm.$t(`labels.${cItem.text}`))+" ")]),_c('b',[_vm._v(_vm._s(cItem.value))])])}),0),_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.countByActionOrder),function(cItem,cKey){return _c('div',{key:`co_${cKey}`},[_c('span',{staticStyle:{"display":"inline-block","width":"70px"}},[_vm._v(" "+_vm._s(_vm.$t(`labels.${cItem.text}`))+" ")]),_c('b',[_vm._v(_vm._s(cItem.value))])])}),0)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"100vw"},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[(_vm.detailDialog)?_c('DashboardDetail',{attrs:{"id-warehouse":_vm.selected.id_warehouse,"id-delivery-company":_vm.selected.id_delivery_company,"state":_vm.selected.state,"date":_vm.filters.date,"id-orders":_vm.selected.id_orders},on:{"cancel":_vm.hideDetail}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }